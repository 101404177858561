import React, { useState, useEffect } from "react";
import { UrlConfig } from "./UrlConfig.js";
import Header from "./public/components/Header";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import Payment from "./public/components/Payment";
import { Text, Spacer, Button, Loading } from "@nextui-org/react";
import avatar from "./public/avatar.svg";

import "../styles/Personal.css";

export default function Personal() {
  const [inviteList, setInviteList] = useState([]);
  const [anchor, setAnchor] = useState(true);
  const navigate = useNavigate();
  const [orderList, setOrderList] = useState([]);
  const [isCheckPayButtonLoading, setIsCheckPaidLoading] = useState(false);
  useEffect(() => {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      getInviteList();
      getUserOrderList();
    }
  }, []);

  function getUserOrderList() {
    let myHeaders = new Headers();
    myHeaders.append("Accept", "*/*");
    const token = localStorage.getItem("token");
    myHeaders.append("Content-Type", "application/json");
    const raw = { token: token };
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
    };
    const url = UrlConfig.order_list;
    fetch(url, requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data && data.code === 200) {
          setOrderList(data.data);
          console.log(data);
        } else {
          message.error(data.msg);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  }

  const checkOrderStatus = (index) => {
    setIsCheckPaidLoading(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const token = localStorage.getItem("token");
    const url = UrlConfig.pay_status;
    const raw = {
      order_id: orderList[index]._id,
      token: token,
    };
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
    };
    fetch(url, requestOptions)
      .then((res) => {
        setIsCheckPaidLoading(false);
        return res.json();
      })
      .then((data) => {
        if (data && data.code === 200) {
          if (data.data.status === "PROCESS_SUCCESS") {
            message.success(
              "订单状态刷新成功，天数已添加，请返回主页然后刷新页面"
            );
          } else if (data.data.status === "SUCCESS") {
            message.error("请联系客服: writefast@163.com 处理");
          } else {
            message.error(
              "未检测到支付结果:" +
                data.message +
                " 状态:" +
                data.data.status +
                " 遇到问题请联系客服: writefast@163.com"
            );
          }
        } else {
          message.error(
            "未检测到支付结果:" +
              data.message +
              " 遇到问题请联系客服: writefast@163.com"
          );
        }
      })
      .catch((err) => {
        setIsCheckPaidLoading(false);
        message.error(err + " 遇到问题请联系客服: writefast@163.com");
      });
  };

  function getInviteList() {
    let myHeaders = new Headers();
    myHeaders.append("Accept", "*/*");
    const token = localStorage.getItem("token");
    myHeaders.append("Content-Type", "application/json");
    const raw = { token: token };
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
    };
    const url = UrlConfig.get_invite_list;
    fetch(url, requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data && data.code === 200) {
          setInviteList(data.data);
          console.log(data);
        } else {
          message.error(data.msg);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  }

  function logout(val) {
    if (val.msg === "logout") {
      navigate("/");
    }
  }
  return (
    <div className="body" style={{ height: "100%" }}>
      <Header logOut={logout} />
      <div className="personal-container flex">
        <div className="left-side mt-16">
          <div
            className={`left-tab ft-14 text-white pointer ${
              anchor ? "actived" : ""
            }`}
            onClick={() => {
              setAnchor(true);
            }}
          >
            我的信息
          </div>
          <div
            className={`left-tab ft-14 text-white pointer ${
              !anchor ? "actived" : ""
            }`}
            onClick={() => {
              setAnchor(false);
            }}
          >
            订单 / 付费升级
          </div>
        </div>
        <div className="right-side">
          {anchor ? (
            <div className="list-container">
              <div
                className="text-white mb-24"
                style={{ color: "rgba(255, 255, 255, 0.4)" }}
              >
                邀请的用户
              </div>
              {inviteList.map((item, index) => {
                return (
                  <div className="flex users" key={index}>
                    <img src={avatar} alt="" />
                    <div className="text-white ml-8">{item.nickname}</div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="right-body">
              <div
                className="text-white mb-24"
                style={{ color: "rgba(255, 255, 255)" }}
              >
                下拉查看订单列表,订单问题请联系客服 writefast@163.com
              </div>
              
              <div
                className="text-white mb-24"
                style={{ color: "rgba(255, 255, 255)" }}
              >
                订单列表:
              </div>
              <ul>
                {orderList.map((order, index) => (
                  <li style={{ color: "white" }} key={index}>
                    <Text color="white">{"订单号: " + order._id}</Text>
                    <Text color="white">{"生成日期: " + new Date(order.createdAt).toLocaleString()}</Text>
                    <Text color="white">{"订单状态: " + order.status}</Text>
                    <Text color="white">{"订单金额: " + order.total/1000+'元'}</Text>
                    {isCheckPayButtonLoading ? (
                          <Loading
                            type="points-opacity"
                            color="white"
                            size="md"
                          >正在检查付款状态中...</Loading>
                        ) : (
                          <Button
                            color="success"
                            onPress={() => checkOrderStatus(index)}
                          >
                            刷新付款状态
                          </Button>
                        )}
                    <Spacer y={1.5}></Spacer>
                  </li>
                ))}
              </ul>
              <Payment/>
            </div>
          )}
        </div>
      </div>
      <div className="footer"></div>
    </div>
  );
}
