import { Dropdown, Text, useTheme, Navbar } from "@nextui-org/react";
import { SendOutlined } from "@ant-design/icons";
import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Loading } from "@nextui-org/react";
import { UrlConfig } from "./UrlConfig.js";
import LoginUtils from "./LoginUtils.js";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import Header from "./public/components/Header";
import Payment from "./public/components/Payment";
import noFree from "./public/no-free.png";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import avatar from "./public/avatar.svg";
import wrLogo from "./public/wr-logo.svg";

import { Modal, Input, message } from "antd";

import "../styles/MobileGenerator.css";
import { ReactMarkdown } from "react-markdown/lib/react-markdown.js";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark as CodeStyle } from "react-syntax-highlighter/dist/esm/styles/prism";

export default function Generator() {
  const ref = useRef(null);
  const navigate = useNavigate();
  const [isLoggedIn, setIsLogin] = useState(new LoginUtils().isLoggedIn());
  const [docList, setDocList] = useState([]);
  const [chatList, setChatList] = useState([]);
  const [chatId, setChatId] = useState(null);
  const [freeCounts, setFreeCounts] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [context, setContext] = useState("");
  const [restDays, setRestDays] = useState("0");
  const [needPay, setNeedPay] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();
  const [visible, setVisible] = React.useState(false);
  const [resultText, setResultText] = React.useState("");
  const { TextArea } = Input;

  function closeHandler(e) {
    setVisible(false);
    setIsLoading(false);
  }

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    if (resultText && resultText !== "") {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(scrollToBottom, [resultText]);

  useEffect(() => {
    const counts = localStorage.getItem("freeCounts");
    if (counts) {
      setFreeCounts(counts);
    } else {
      setFreeCounts(3);
      localStorage.setItem("freeCounts", 3);
    }
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      const temp = JSON.parse(userInfo);
      const restDay = moment(temp.usageExpireDate)
        .diff(moment(), "days")
        .toString();
      localStorage.setItem("restDay", restDay);
      setRestDays(restDay);
    }
    if (
      navigator.userAgent.match(/Mobi/i) ||
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/iPhone/i)
    ) {
      setIsMobile(true);
      setChatId(location.state.chatId);
      console.log(location.state.chatList);
      if (location.state.chatList)
        setChatList(JSON.parse(location.state.chatList));

    }
  }, []);


  function goToRegister() {
    navigate("/login", { state: { type: "register" } });
  }

  function logout(val) {
    if (val.msg === "logout") {
      setRestDays("0");
    }
  }

  function handleExpire(val) {
    if (val.msg === "expire") {
      setNeedPay(true);
    }
  }


  function askNeo() {
    if (isLoading) return;
    const ctrl = new AbortController();
    setIsLoading(true);
    let stream_result = "";
    let body = null;
    const token = localStorage.getItem("token");
    let temp = JSON.parse(JSON.stringify(chatList));
    if (resultText && resultText !== "") {
      const lastTalk = {
        role: "assistant",
        content: JSON.parse(JSON.stringify(resultText)),
      };
      temp.push(lastTalk);
      setResultText("");
    }
    const newLine = {
      role: "user",
      content: context,
    };
    temp.push(newLine);
    setChatList(temp);
    setContext("");
    if (token) {
      body = {
        prompt_list: temp,
        chat_id: chatId,
        token: token,
      };
    } else {
      body = {
        prompt_list: temp,
        chat_id: chatId,
      };
    }
    fetchEventSource(UrlConfig.post_chat_by_list, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
      signal: ctrl.signal,
      async onopen(response) {
        if (response.ok) {
          return; // everything's good
        } else if (response.status === 451) {
          setIsLoading(false);
          message.error("内用违反相关规定，无法展示");
          ctrl.abort();
        } else if (response.status === 403) {
          setIsLoading(false);
          message.error("剩余天数不足, 请购买VIP");
          ctrl.abort();
        } else {
          setIsLoading(false);
          message.error("对不起，服务器开小差了！请创建一个新对话");
          ctrl.abort();
        }
      },
      onmessage(msg) {
        let data = JSON.parse(msg.data);
        stream_result = stream_result + data.content;
        setResultText(stream_result);
      },
      onclose() {
        // if the server closes the connection unexpectedly, retry:
        console.log("Connection closed");
        setIsLoading(false);
      },
      onerror(err) {
        setIsLoading(false);
        console.log(err);
        ctrl.abort();
        throw Error("stop retrying");
      },
    });
  }

  return (
    <div>
      <div className="body">
        <Header logOut={logout} expire={handleExpire} />
        <div
          className={`generator-body ${
            isMobile ? "p-l-r-16" : "flex p-l-r-48"
          }`}
        >
          <div>
            <Modal
              open={visible}
              onCancel={closeHandler}
              style={{
                top: 200,
              }}
              footer={null}
            >
              <div className="text-center">
                <img className="no-free" src={noFree}></img>
              </div>
              <div className="text-center white font-400 font-24">
                你的使用次数已经用完
              </div>
              <div className="text-center font-400 font-14 mt-8 mb-24 text-grey">
                赶快登录享受更多新体验吧～
              </div>
              <div className="">
                <div
                  className="now-register pointer radius-8 text-center font-16 font-600"
                  onClick={goToRegister}
                >
                  现在注册
                </div>
              </div>
            </Modal>
          </div>
          <div className="generator-right">
            {needPay ? (
              <div className="right-body">
                <Payment />
              </div>
            ) : (
              <div className="right-body-mobile">
                <div style={{ position: "relative" }}>
                  {chatList.map((item, index) => {
                    return (
                      <div
                        style={{
                          whiteSpace: "pre-line",
                          color: "rgba(255, 255, 255, 0.6)",
                        }}
                      >
                        {item.role === "user" ? (
                          <div className="flex user-side">
                            <div className="user-chat bg-chat">
                              {item.content}
                            </div>
                            <img
                              style={{
                                maxWidth: "40px",
                                width: "40px",
                                position: "relative",
                                top: "12px",
                              }}
                              className="ml-4"
                              src={avatar}
                            ></img>
                          </div>
                        ) : (
                          <div
                            style={{
                              whiteSpace: "pre-line",
                              color: "rgba(255, 255, 255, 0.6)",
                            }}
                            className="gpt-side flex"
                          >
                            <div>
                              <img
                                className="mr-4"
                                style={{
                                  maxWidth: "40px",
                                  width: "40px",
                                  position: "relative",
                                  top: "10px",
                                }}
                                src={wrLogo}
                              ></img>
                            </div>
                            <div className="user-chat bg-chat">
                              {item.content.trimStart()}
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                  {resultText && resultText !== "" ? (
                    <div
                      style={{
                        whiteSpace: "pre-line",
                        color: "rgba(255, 255, 255, 0.6)",
                        padding: "10px",
                      }}
                      className="gpt-side flex"
                    >
                      <div>
                        <img
                          className="mr-4"
                          style={{
                            maxWidth: "40px",
                            width: "40px",
                            position: "relative",
                            top: "10px",
                          }}
                          src={wrLogo}
                        ></img>
                      </div>
                      <div className="user-chat bg-chat">
                        {resultText.trimStart()}
                      </div>
                      <div ref={messagesEndRef} />
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            )}
            <div className="ask-again fixed">
              {parseInt(restDays) > 0 ? (
                <div></div>
              ) : (
                <div className="hint">
                  <span className="text-grey">再提问</span> {freeCounts}{" "}
                  <span className="text-grey">个问题后，需要登录注册</span>
                </div>
              )}
              <TextArea
                placeholder="请输入你的问题"
                rows={1}
                value={context}
                onChange={(e) => setContext(e.target.value)}
              />
              {isLoading ? (
                <Loading
                  type="points-opacity"
                  color="white"
                  style={{ position: "relative", top: "-35px", left: "91%" }}
                ></Loading>
              ) : (
                <SendOutlined
                  style={{
                    color: "white",
                    position: "relative",
                    top: "-35px",
                    left: "91%",
                  }}
                  onClick={askNeo}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
