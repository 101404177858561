import React, { useState, useEffect, forceUpdate } from "react";
import { UrlConfig } from "../../UrlConfig.js";
import QRCode from 'qrcode.react';
import { Loading } from "@nextui-org/react";
import '../../../styles/payment.css';
import '../../../styles/global.css';

import { Modal,message } from "antd";
import { FormItemPrefixContext } from "antd/es/form/context";

function Payment(props) {
    const [qrCode, setQrCode] = useState('')
    const [payModal, setPayModal] = useState(false)
    const [priceModel, setPriceModel] = useState([])
    const [sku, setSku] = useState(null)
    const [orderId,setOrderId] = useState('')
    const [isLoading, setIsLoading] = useState([
        {id: '0', value: 'false'},
        {id: '1', value: 'false'},
        {id: '3', value: 'false'},
        {id: '6', value: 'false'}
    ]);
    const [isCheckPaidLoading,setIsCheckPaidLoading] = useState(false)
    const [isProductLoading,setIsProductLoading] = useState(true)
    useEffect(() => {
        getPrice()
    }, [])

    function back() {
        props.back({msg: 'back'})
    }

    function getPrice() {
        setIsProductLoading(true)
        let myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/json") 
        const url = UrlConfig.get_price  
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({})
        }
        fetch(url, requestOptions).then(res => {
            setIsProductLoading(false)
            return res.json()
        }).then(data => {
            if (data && data.code === 200) {
                setPriceModel(data.data)
            }else{
                message.error("产品加载失败，请稍后再试")
            }
        }).catch(err => {
            setIsProductLoading(false)
            message.error("产品加载失败，请稍后再试")
        })
    }

    function buyNow(sku) {
        const temp = isLoading.filter(item => {
            return item.value === 'true'
        })
        if (temp.length > 0) return
        setIsLoading(isLoading.map(item => {
            if (item.id === sku) {
                return {...item, value: 'true'}
            }
            return item
        }))
        let myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/json")
        const token = localStorage.getItem('token')
        const url = UrlConfig.get_pay_qrcode
        const raw = {
            sku: sku,
            token: token  
        }
        switch (sku) {
            case '0':
                setSku('快写套餐')
                break;
            case '1':
                setSku('快写一个月套餐')
                break;
            case '3':
                setSku('快写三个月套餐')
                break;
            case '6':
                setSku('快写半年套餐')
                break;
            default:
                break;
        }
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify(raw)
        }
        fetch(url, requestOptions).then(res => {
            return res.json()
        }).then(data => {
            if (data && data.code === 200) {
                setOrderId(data.data.order_id)
                setQrCode(data.data.qr_code_url)
                setIsLoading(isLoading.map((str, i) => (i === parseInt(sku) ? 'false' : str)));
                setPayModal(true)
            }
        })
    }

    function closeHandler() {
        setPayModal(false)
    }

    function paid () {
        setIsCheckPaidLoading(true)
        let myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/json")
        const token = localStorage.getItem('token')
        const url = UrlConfig.pay_status
        const raw = {
            order_id: orderId,
            token: token  
        }
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify(raw)
        }
        fetch(url, requestOptions).then(res => {
            setIsCheckPaidLoading(false)
            return res.json()
        }).then(data => {
            if (data && data.code === 200) {
                if(data.data.status==='PROCESS_SUCCESS'){
                    setPayModal(false)
                    window.location.reload()
                }else{
                    message.error("未检测到支付结果:"+data.message+" 状态:"+data.data.status)
                }
            }else{
                message.error("未检测到支付结果:"+data.message)
            }
        })
        
    }
    return (
        <div className="payment-body flex">
            <div>
                <div className="cards mt-8">
                    {priceModel && priceModel.length > 0 ? (
                        <div className="flex">
                            {priceModel.map((item, index) => {
                                return <div className="pay-card" key={index}>
                                    <div className="card-title text-center font-12">{item.name}</div>
                                    <div className="card-body">
                                        <div className="pay-price text-center mt-8 font-32 font-600">{item.price / 100}元</div>
                                        <div className="text-center font-600 text-grey font-16 mt-8 pb-14 text-decoration-line">{item.priceBefore / 100}元</div>
                                        <div className="text-center font-600 text-grey font-14 mt-8 pb-14">{item.description}</div>
                                        <div className="text-white font-24 text-center mt-12 pb-14 pointer" onClick={() => { buyNow(item.sku) }}>
                                            {isLoading.filter(ele => {
                                                return ele.id === item.sku && ele.value === 'true'
                                            }).length > 0 ? (
                                                <Loading type="points-opacity" color="white" size="lg" />
                                            ): (
                                                <div>立即购买</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    ) : (<div></div>)}
                </div>
                {
                    isProductLoading?<Loading type="points-opacity" color="white" size="lg">获取产品列表中</Loading>:<div className="btn text-white upgrade pointer back-btn" onClick={back}>返回</div>
                }
                
                
                <Modal
                    open={payModal}
                    onCancel={closeHandler}
                    style={{
                    top: 200,
                    }}
                    footer={null}>
                    <div className="text-center white font-400 font-24">
                        请使用微信扫码支付
                    </div>
                    <div className="text-center font-400 font-14 mt-8 mb-24 white">
                        套餐：{sku}
                    </div>
                    <div className="text-center mt-22">
                        <QRCode
                            value={qrCode} //value参数为字符串类型
                            size={200} //二维码的宽高尺寸
                            fgColor="#000000"  //二维码的颜色
                        />
                    </div>
                    <div className="text-center font-400 font-14 mt-8 mb-24 white">
                        付款完成后请点击"付款完成", 刷新页面更新使用期限&#10;
                        如有问题请发邮件至客服邮箱: writefast@163.com
                    </div>
                    {
                        isCheckPaidLoading?<Loading className="text-center mt-22" type="points-opacity" color="white" size="lg"></Loading>:<div className="paid btn-default pointer register-btn" onClick={paid} style={{width: '50%', margin: '0 auto'}}>付款完成</div>
                    }
                </Modal>
            </div>

        </div>
    )
}

export default Payment;