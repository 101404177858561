import React, { useState, useEffect, useRef } from "react";
import { Input, message, Modal } from 'antd';
import AcmeLogo from "./public/logo.svg";
import { useNavigate } from "react-router-dom";
import { Loading } from "@nextui-org/react";
import '../styles/Login.css';
import { useLocation } from "react-router-dom";
import { UrlConfig } from "./UrlConfig.js";
import fetchJsonp from'fetch-jsonp';
import { ArrowRightOutlined } from '@ant-design/icons';
import noFree from "./public/no-free.png";

export default function Login() {
    const [phone, setPhone] = useState('')
    const [code, setCode] = useState('')
    const [inviteCode, setInviteCode] = useState('')
    const [userInfo, setUserInfo] = useState(null)
    const [countDown, setCountDown] = useState(0)
    const [isLoading, setIsLoading] = useState(false);
    const timeRef = useRef()//设置延时器
    const [inType, setInType] = useState(null)
    const location = useLocation();
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false)


    useEffect(() => {
        if (location.state) {
            setInType(location.state.type)
        } else {
            setInType('register')
        }
        const code = window.location.href.split('=')[1]
        if (code) {
            setInviteCode(code)
        }
    }, [])

    useEffect(() => {
        if(countDown && countDown > 0)
            timeRef.current=setTimeout(()=>{
                setCountDown(countDown => countDown-1)
            },1000)
        //清楚延时器
        return ()=>{
            clearTimeout(timeRef.current)
        }
    }, [countDown])

    function sendCode() {
        var regExp = new RegExp("^1[3578]\\d{9}$")
        if (regExp.test(phone)) {
            if (countDown === 0) {
                setCountDown(60)
                let myHeaders = new Headers()
                myHeaders.append("Content-Type", "application/json")
                const requestOptions = {
                  method: "POST",
                  headers: myHeaders,
                  body: JSON.stringify({phone: phone})
                }
                const url = UrlConfig.send_code_url
                fetch(url, requestOptions).then(res => {
                    return res.json()
                }).then(data => {
                    if (data.code === 200) {
                        message.success('验证码已发送')
                    } else {
                        message.info(data.message)
                    }
                })
            }
        } else {
            message.error('请输入正确的手机号')
        }
    }

    function registerOrLogin() {
        if (isLoading) return
        let myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/json")
        setIsLoading(true)
        if (inType === 'login') {
            const raw = {
                phone: phone,
                code: code  
            }
            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: JSON.stringify(raw)
            }
            const url = UrlConfig.login_url
            fetch(url, requestOptions).then((res) => {
                return res.json()
            }).then(data => {
                if (data.code !== 200) {
                    message.error(data.message)
                    setIsLoading(false)
                } else {
                    message.success('登录成功')
                    localStorage.setItem('token', data.data.token)
                    getUserInfo(data.data.token)
                }
            }).catch(err => {
                setIsLoading(false)
                message.error(err)
            })
        } else {
            const raw = {
                phone: phone,
                code: code,
                invite_code: inviteCode      
            }
            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: JSON.stringify(raw)
            }
            const url = UrlConfig.register_url
            fetch(url, requestOptions).then((res) => {
                return res.json()
            }).then(data => {
                if (data.code !== 200) {
                    message.error(data.message)
                    setIsLoading(false)
                } else {
                    setVisible(true)
                    localStorage.setItem('token', data.data.token)
                }
            }).catch(err => {
                message.error(err)
                setIsLoading(false)
            })
        }
    }

    function registerSuccess() {
        const token = localStorage.getItem('token')
        getUserInfo(token)
    }

    function getUserInfo(id_token) { // 获取用户信息
        let myHeaders = new Headers()
        myHeaders.append("Accept", "*/*")
        myHeaders.append("Content-Type", "application/json")
        const raw = {token: id_token}
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify(raw)
        }
        const url = UrlConfig.get_user_info_url
        fetch(url, requestOptions).then(res => {
            return res.json()
        }).then(data => {
            if (data && data.code === 200) {
                setUserInfo(data.data)
                localStorage.setItem("userInfo", JSON.stringify(data.data))
                setVisible(false)
                navigate('/generator')
                setIsLoading(false)
            } else {
                message.error(data.message)
                setVisible(false)
                setIsLoading(false)
            }
        }).catch(err => {
            message.error(err)
            setVisible(false)
            setIsLoading(false)
        })
    }

    return (
        <div className="login-body flex">
            <div className="login-container">
                {/* <button onClick={getInfo}>测试</button> */}
                <div className="text-center pointer" onClick={() => {navigate('/')}}><img src={AcmeLogo}></img></div>
                <div className="mb-24"><Input placeholder="手机号" onChange={(e) => setPhone(e.target.value)}></Input></div>
                <div className="mb-24" style={{position: 'relative'}}>
                    <Input placeholder="请输入验证码" onChange={(e) => setCode(e.target.value)}></Input>
                    <div className={`register btn ml-16 font-600 radius-8 send-code ${countDown > 0 ? 'not-allow' : 'pointer'}`} onClick={() => {sendCode()}}>
                        {countDown === 0 ? '发送验证码' : `重发(${countDown}s)`}
                    </div>
                </div>
                {inType === 'login' ? (<div></div>) : (
                    <div className="mb-24"><Input placeholder="邀请码(选填)" onChange={(e) => setInviteCode(e.target.value)} value={inviteCode}></Input></div>
                )}
                <div
                    className="btn-default pointer register-btn" style={{width: '100%'} } onClick={registerOrLogin}
                >
                    {isLoading ? (
                        <Loading type="points-opacity" color="white" size="lg" />
                    ) : (
                        <div >
                            {inType === 'login' ? '登录' : '注册'}
                        </div>
                    )}
                </div>
                {inType === 'login' ? (<div className="text-center mt-6">
                    <span className="text-grey">还没有账号？</span>
                    <span className="pointer" style={{color: 'white'}} onClick={() => {setInType('register')}}> 去注册 <ArrowRightOutlined /></span>
                </div>) : (
                    <div className="text-center mt-6">
                        <span className="text-grey">已有账号，现在</span>
                        <span className="pointer" style={{color: 'white'}} onClick={() => {setInType('login')}}> 去登录 <ArrowRightOutlined /></span>
                    </div>
                )}
            </div>
            <Modal
              open={visible}
              onCancel={registerSuccess}
              style={{
                top: 200,
              }}
              footer={null}
            >
              <div className="text-center">
                <img className="no-free" src={noFree}></img>
              </div>
              <div className="text-center white font-400 font-24">
                恭喜注册成功！
              </div>
              <div className="text-center font-400 font-14 mt-8 mb-24 text-grey">
                你获得快写 7 天的使用期限，你还可以购买期限来解锁1个月或更长时间的使用权限
              </div>
              <div className="">
                <div
                  className="now-register pointer radius-8 text-center font-16 font-600"
                  onClick={registerSuccess}
                >
                  现在体验
                </div>
              </div>
            </Modal>
        </div>
    )
}