import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoginUtils from "./LoginUtils";

function Callback() {
 const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    let id_token = searchParams.get("id_token");
    if (id_token === "") {
      alert("登录失败，请重新登录");
    } else {
      let utils = new LoginUtils()
      utils.saveToken(id_token)
      navigate("/generator");
    }
  },[]);

  return <></>;
}

export default Callback;
