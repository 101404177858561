import { Dropdown, Text, useTheme, Navbar } from "@nextui-org/react";
import React, { useState, useEffect } from "react";
import { useRef } from "react";
import { Loading } from "@nextui-org/react";
import { UrlConfig } from "./UrlConfig.js";
import LoginUtils from "./LoginUtils.js";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Header from "./public/components/Header";
import Payment from "./public/components/Payment";
import generator from "./public/generator.svg";
import copy from "./public/copy.svg";
import noFree from "./public/no-free.png";
import avatar from "./public/avatar.svg";
import wrLogo from "./public/wr-logo.svg";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { PlusOutlined, SendOutlined, EditOutlined } from "@ant-design/icons";
import moment from "moment";

import { Modal, Input, Select, message } from "antd";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark as CodeStyle } from "react-syntax-highlighter/dist/esm/styles/prism";
import "../styles/Generator.css";
import { ReactMarkdown } from "react-markdown/lib/react-markdown.js";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";

export default function Generator() {
  const ref = useRef(null);
  const navigate = useNavigate();
  const [isLoggedIn, setIsLogin] = useState(new LoginUtils().isLoggedIn());
  const [docList, setDocList] = useState([]);
  const [context, setContext] = useState("");
  const [freeCounts, setFreeCounts] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [successCopy, setSuccessCopy] = useState(false);
  const [restDays, setRestDays] = useState("0");
  const [needPay, setNeedPay] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [chatId, setChatId] = useState(null);
  const [chatList, setChatList] = useState([]);
  const [historyChat, setHistoryChat] = useState([]);
  const location = useLocation();
  const [tempChatId, setTempChatId] = useState("");
  const [tempChatName, setTempChatName] = useState("");
  const [changeNameDialog, setChangeNameDialgo] = useState(false);
  const options = [
    { value: "帮我总结这段话", label: "帮我总结这段话" },
    { value: "帮我优化这段话", label: "帮我优化这段话" },
    { value: "帮我重写这段话", label: "帮我重写这段话" },
    { value: "帮我加长这段话", label: "帮我加长这段话" },
    { value: "帮我缩短这段话", label: "帮我缩短这段话" },
    { value: "帮我把这段话分类", label: "帮我把这段话分类" },
    { value: "帮我修改语法错误", label: "帮我修改语法错误" },
    { value: "帮我生成一篇文章", label: "帮我生成一篇文章" },
    { value: "提问", label: "提问" },
  ];
  const [selected, setSelected] = React.useState(new Set(["提问"]));
  const [selection, setSelection] = React.useState("提问");
  const [visible, setVisible] = React.useState(false);
  const [resultText, setResultText] = React.useState("");
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    if (resultText && resultText !== "") {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(scrollToBottom, [resultText]);
  const selectedValue = React.useMemo(
    () => Array.from(selected).join(", ").replaceAll("_", " "),
    [selected]
  );

  function handleLoginClick(e) {
    window.open(new LoginUtils().buildLoginUrl(), "_self");
  }

  function handleLogOutClick(e) {
    new LoginUtils().logOut();
    setIsLogin(new LoginUtils().isLoggedIn());
  }

  function closeHandler(e) {
    setVisible(false);
    setIsLoading(false);
  }

  const copyText = () => {
    setSuccessCopy(true);
    setTimeout(() => {
      setSuccessCopy(false);
    }, 2000);
  };

  const handleSelect = (value) => {
    setSelection(value);
  };

  useEffect(() => {
    setIsLogin(new LoginUtils().isLoggedIn());
    const counts = localStorage.getItem("freeCounts");
    if (counts) {
      setFreeCounts(counts);
    } else {
      setFreeCounts(3);
      localStorage.setItem("freeCounts", 3);
    }
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      const temp = JSON.parse(userInfo);
      const restDay = moment(temp.usageExpireDate)
        .diff(moment(), "days")
        .toString();
      setRestDays(restDay);
    }
    if (
      navigator.userAgent.match(/Mobi/i) ||
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/iPhone/i)
    ) {
      setIsMobile(true);
    }

    if (location.state && location.state.type === "pay") {
      setNeedPay(true);
    }

    getHistoryChat();
  }, []);

  function goToRegister() {
    navigate("/login", { state: { type: "register" } });
  }

  function logout(val) {
    if (val.msg === "logout") {
      setRestDays("0");
    }
  }

  function handleExpire(val) {
    if (val.msg === "expire") {
      setNeedPay(true);
    }
  }

  function handleGoPay(val) {
    if (val.msg === "pay") {
      setNeedPay(true);
    }
  }

  function handlePayBack(val) {
    if (val.msg === "back") {
      setNeedPay(false);
    }
  }

  function createNewChat(str) {
    if (isLoading) return;
    setIsLoading(true);
    let myHeaders = new Headers();
    myHeaders.append("Accept", "*/*");
    const token = localStorage.getItem("token");
    myHeaders.append("Content-Type", "application/json");
    const raw = token ? { token: token } : {};
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
    };
    const url = UrlConfig.create_new_chat;
    fetch(url, requestOptions)
      .then((res) => {
        setIsLoading(false);
        return res.json();
      })
      .then((data) => {
        if (data && data.code === 200) {
          setChatId(data.data.chat_id);
          if (isMobile) {
            navigate("/mobile-generator", {
              state: { chatId: data.data.chat_id },
            });
          } else {
            if (chatList.length === 0 && str !== "btn") {
              askNeo(data.data.chat_id);
            } else {
              setChatList([]);
              setResultText("");
            }
          }
        }
        else if (data.code === 401){
          setIsLoading(false);
          message.error("未登录或登录过期,请重新登录");
        } 
        else {
          setIsLoading(false);
          message.error(data.msg);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        message.error(err);
      });
  }

  function askNeo(id) {
    if (isLoading) return;
    let body = null;
    const token = localStorage.getItem("token");
    let temp = JSON.parse(JSON.stringify(chatList));
    if (resultText && resultText !== "") {
      const lastTalk = {
        role: "assistant",
        content: JSON.parse(JSON.stringify(resultText)),
      };
      temp.push(lastTalk);
      setResultText("");
    }
    const newLine = {
      role: "user",
      content: context,
    };
    temp.push(newLine);
    setChatList(temp);
    setContext("");
    if (token) {
      body = {
        prompt_list: temp,
        chat_id: id,
        token: token,
      };
      askGpt(body);
    } else {
      message.info("请先登录")
    }
  }

  function askGpt(body) {
    const ctrl = new AbortController();
    setIsLoading(true);
    let stream_result = "";
    fetchEventSource(UrlConfig.post_chat_by_list, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
      signal: ctrl.signal,
      async onopen(response) {
        if (response.ok) {
          return; // everything's good
        } else if (response.status === 451) {
          setIsLoading(false);
          alert("内用违反相关规定，无法展示");
          ctrl.abort();
        } else if (response.status === 403) {
          setIsLoading(false);
          message.error("剩余天数不足, 请购买VIP");
          ctrl.abort();
        } else if (response.status === 401){
          setIsLoading(false);
          message.error("未登录或登录过期,请重新登录");
          ctrl.abort();
        }
         else {
          setIsLoading(false);
          message.error("对不起，服务器开小差了！请创建一个新对话");
          ctrl.abort();
        }
      },
      onmessage(msg) {
        let data = JSON.parse(msg.data);
        stream_result = stream_result + data.content;
        setResultText(stream_result);
      },
      onclose() {
        // if the server closes the connection unexpectedly, retry:
        console.log("Connection closed");
        setIsLoading(false);
      },
      onerror(err) {
        setIsLoading(false);
        console.log(err);
        ctrl.abort();
        throw Error("stop retrying");
      },
    });
  }
  function getHistoryChat() {
    let myHeaders = new Headers();
    myHeaders.append("Accept", "*/*");
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoadingHistory(true);
      myHeaders.append("Content-Type", "application/json");
      const raw = { token: token, page_num: 0 };
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(raw),
      };
      const url = UrlConfig.get_history_chat;
      fetch(url, requestOptions)
        .then((res) => {
          setIsLoadingHistory(false);
          return res.json();
        })
        .then((data) => {
          if (data && data.code === 200) {
            // let temp = []
            // data.data.history_list.forEach(item => {
            //   const tempItem = {
            //     ...item,
            //     active: false
            //   }
            //   temp.push(tempItem)
            // })
            setHistoryChat(data.data.history_list);
          } else if(data && data.code === 401){
            message.error("登陆过期，拉取历史记录失败，请重新登录");
          }
          else {
            message.error(data.msg);
          }
        })
        .catch((err) => {
          message.error(err);
          setIsLoadingHistory(false);
        });
    }
  }

  function callHistory(index) {
    const temp = JSON.parse(JSON.stringify(historyChat[index]));
    setChatList(temp.message_list);
    setChatId(temp._id);
    setResultText("");
    if (isMobile)
      navigate("/mobile-generator", {
        state: {
          chatId: temp._id,
          chatList: JSON.stringify(temp.message_list),
        },
      });
  }

  function changeNameOpen(id, name, index) {
    setTempChatId(id);
    setTempChatName(name);
    callHistory(index);
    setChangeNameDialgo(true);
  }

  function confirmChangeChatName() {
    const url = UrlConfig.change_chat_name;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "*/*");
    const token = localStorage.getItem("token");
    if (token) {
      myHeaders.append("Content-Type", "application/json");
      const raw = {
        token: token,
        chat_id: tempChatId,
        chat_name: tempChatName,
      };
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(raw),
      };
      fetch(url, requestOptions)
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data && data.code === 200) {
            setTempChatId("");
            setTempChatName("");
            setChangeNameDialgo(false);
            getHistoryChat();
          } else {
            message.error(data.msg);
          }
        })
        .catch((err) => {
          message.error(err);
        });
    }
  }

  return (
    <div>
      <div className="body">
        <Header logOut={logout} expire={handleExpire} goPay={handleGoPay} />
        <div
          className={`generator-body ${
            isMobile ? "p-l-r-16" : "flex p-l-r-48"
          }`}
        >
          <div className={`${isMobile ? "" : "generator-left"}`}>
            <div className="hint">
              <div
                className="create-chat pointer"
                onClick={() => {
                  createNewChat("btn");
                }}
              >
                <PlusOutlined />
                {isLoading ? (
                  <Loading
                    type="points-opacity"
                    color="white"
                    size="lg"
                    style={{ color: "white", left: "45%" }}
                  ></Loading>
                ) : (
                  <span>创建一个新对话</span>
                )}
              </div>
            </div>
            <div
              style={{ overflowY: "scroll", maxHeight: "85%" }}
              className="mt-8"
            >
              {isLoadingHistory ? (
                <Loading
                  color="white"
                  size="lg"
                  style={{ color: "white", marginTop: "15vh", left: "40%" }}
                >
                  历史加载中
                </Loading>
              ) : (
                historyChat.map((item, index) => {
                  return (
                    <div
                      className="history-item flex pointer text-white"
                      key={item._id}
                      onClick={() => {
                        callHistory(index);
                      }}
                    >
                      <span>
                        {item.chat_name
                          ? item.chat_name
                          : `历史对话${index + 1}`}
                      </span>
                      <span className="flex-1 text-right">
                        <EditOutlined
                          onClick={() => {
                            changeNameOpen(item._id, item.chat_name, index);
                          }}
                        />
                      </span>
                    </div>
                  );
                })
              )}
            </div>
            <Modal
              open={changeNameDialog}
              onCancel={() => {
                setTempChatId("");
                setTempChatName("");
                setChangeNameDialgo(false);
              }}
              style={{
                top: 200,
              }}
              footer={null}
            >
              <div className="text-center white font-400 font-24 mb-24">
                修改历史对话名称
              </div>
              <div className="text-center white font-400 font-24 mb-24">
                <Input
                  value={tempChatName}
                  onChange={(e) => setTempChatName(e.target.value)}
                ></Input>
              </div>
              <div className="">
                <div
                  className="now-register pointer radius-8 text-center font-16 font-600"
                  onClick={confirmChangeChatName}
                >
                  确定修改
                </div>
              </div>
            </Modal>
            <Modal
              open={visible}
              onCancel={closeHandler}
              style={{
                top: 200,
              }}
              footer={null}
            >
              <div className="text-center">
                <img className="no-free" src={noFree}></img>
              </div>
              <div className="text-center white font-400 font-24">
                你的使用次数已经用完
              </div>
              <div className="text-center font-400 font-14 mt-8 mb-24 text-grey">
                赶快登录享受更多新体验吧～
              </div>
              <div className="">
                <div
                  className="now-register pointer radius-8 text-center font-16 font-600"
                  onClick={goToRegister}
                >
                  现在注册
                </div>
              </div>
            </Modal>
          </div>
          <div
            className="generator-right"
            style={{ display: isMobile ? "none" : "auto" }}
          >
            {needPay ? (
              <div className="right-body">
                <Payment back={handlePayBack} />
              </div>
            ) : (
              <div style={{ height: "100%" }}>
                <div className="right-body">
                  <div style={{ position: "relative" }}>
                    {chatList.map((item, index) => {
                      return (
                        <div
                          style={{
                            whiteSpace: "pre-line",
                            color: "rgba(255, 255, 255, 0.6)",
                          }}
                        >
                          {item.role === "user" ? (
                            <div className="flex user-side">
                              <div
                                className="user-chat bg-chat"
                                style={{ paddingRight: "10px" }}
                              >
                                {item.content}
                              </div>

                              <img
                                style={{
                                  maxWidth: "40px",
                                  width: "40px",
                                  position: "relative",
                                  top: "12px",
                                }}
                                className="ml-4"
                                src={avatar}
                              ></img>
                            </div>
                          ) : (
                            <div>
                              {item.content && item.content !== "" ? (
                                <div
                                  style={{
                                    whiteSpace: "pre-line",
                                    color: "rgba(255, 255, 255, 0.6)",
                                    padding: "10px",
                                  }}
                                  className="gpt-side flex"
                                >
                                  <div>
                                    <img
                                      className="mr-4"
                                      style={{
                                        maxWidth: "40px",
                                        width: "40px",
                                        position: "relative",
                                        top: "10px",
                                      }}
                                      src={wrLogo}
                                    ></img>
                                  </div>
                                  {
                                    // !item.content.trimStart().includes("```") ? (
                                    //   <div className="user-chat gpt-container">
                                    //     {item.content.trimStart()}
                                    //   </div>
                                    // ) : (
                                    <ReactMarkdown
                                      remarkPlugins={[remarkGfm, remarkMath]}
                                      rehypePlugins={[rehypeKatex]}
                                      className="user-chat gpt-container"
                                      children={item.content.trimStart()}
                                      components={{
                                        ol: ({ node, ...props }) => (
                                          <ol
                                            style={{ color: "white" }}
                                            {...props}
                                          />
                                        ),
                                        li: ({ node, ...props }) => (
                                          <li
                                            style={{ color: "white" }}
                                            {...props}
                                          />
                                        ),
                                        code({
                                          node,
                                          inline,
                                          className,
                                          children,
                                          ...props
                                        }) {
                                          const match = /language-(\w+)/.exec(
                                            className || ""
                                          );
                                          return !inline && match ? (
                                            <SyntaxHighlighter
                                              {...props}
                                              children={String(
                                                children
                                              ).replace(/\n$/, "")}
                                              style={CodeStyle}
                                              language={match[1]}
                                              PreTag="div"
                                            />
                                          ) : (
                                            <code
                                              {...props}
                                              className={className}
                                              style={{
                                                backgroundColor: "transparent",
                                                color: "white",
                                              }}
                                            >
                                              {children}
                                            </code>
                                          );
                                        },
                                      }}
                                    />
                                    //)
                                  }
                                  <div className="copy-btn radius-8">
                                    <CopyToClipboard
                                      onCopy={copyText}
                                      text={item.content}
                                    >
                                      <img src={copy} className="pointer"></img>
                                    </CopyToClipboard>
                                    <div
                                      className={`copy-hint ${
                                        successCopy ? "show-hint" : ""
                                      }`}
                                    >
                                      <p>内容已复制</p>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div></div>
                              )}
                            </div>
                          )}
                        </div>
                      );
                    })}
                    <div>
                      {resultText && resultText !== "" ? (
                        <div
                          style={{
                            whiteSpace: "pre-line",
                            color: "rgba(255, 255, 255, 0.6)",
                            padding: "10px",
                          }}
                          className="gpt-side flex"
                        >
                          <div>
                            <img
                              className="mr-4"
                              style={{
                                maxWidth: "40px",
                                width: "40px",
                                position: "relative",
                                top: "10px",
                              }}
                              src={wrLogo}
                            ></img>
                          </div>
                          {
                            // !resultText.trimStart().includes("```") ? (
                            //   <div className="user-chat gpt-container">
                            //     {resultText.trimStart()}
                            //   </div>
                            // ) : (
                            <ReactMarkdown
                              remarkPlugins={[remarkGfm, remarkMath]}
                              rehypePlugins={[rehypeKatex]}
                              className="user-chat gpt-container"
                              children={resultText.trimStart()}
                              components={{
                                ol: ({ node, ...props }) => (
                                  <ol style={{ color: "white" }} {...props} />
                                ),
                                li: ({ node, ...props }) => (
                                  <li style={{ color: "white" }} {...props} />
                                ),
                                code({
                                  node,
                                  inline,
                                  className,
                                  children,
                                  ...props
                                }) {
                                  const match = /language-(\w+)/.exec(
                                    className || ""
                                  );
                                  return !inline && match ? (
                                    <SyntaxHighlighter
                                      {...props}
                                      children={String(children).replace(
                                        /\n$/,
                                        ""
                                      )}
                                      style={CodeStyle}
                                      language={match[1]}
                                      PreTag="div"
                                    />
                                  ) : (
                                    <code
                                      {...props}
                                      className={className}
                                      style={{
                                        backgroundColor: "transparent",
                                        color: "white",
                                      }}
                                    >
                                      {children}
                                    </code>
                                  );
                                },
                              }}
                            />
                            //)
                          }
                          <div ref={messagesEndRef} />
                          <div className="copy-btn radius-8">
                            <CopyToClipboard
                              onCopy={copyText}
                              text={resultText}
                            >
                              <img src={copy} className="pointer"></img>
                            </CopyToClipboard>
                            <div
                              className={`copy-hint ${
                                successCopy ? "show-hint" : ""
                              }`}
                            >
                              <p>内容已复制</p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                  {/* {resultText === "" ? (
                  <div></div>
                ) : (
                  <CopyToClipboard text={resultText} onCopy={copyText}>
                    <div className="copy-btn radius-8">
                      <img src={copy} className="pointer"></img>
                      <div
                        className={`copy-hint ${
                          successCopy ? "show-hint" : ""
                        }`}
                      >
                        <p>内容已复制</p>
                      </div>
                    </div>
                  </CopyToClipboard>
                )} */}
                </div>
                <div className="bottom-container flex mt-12">
                  {/* <div style={{ width: '160px' }}>
                    <Select
                            defaultValue={selection}
                            onChange={handleSelect}
                            options={options}
                            style={{ width: '160px' }}
                    />
                  </div> */}
                  <div style={{ flex: "1" }}>
                    <Input.TextArea
                      value={context}
                      rows={1}
                      onChange={(e) => setContext(e.target.value)}
                      placeholder="请输入句子, 关键词, 段落, 问题, 翻译     Enter 发送, Shift+Enter 换行"
                      id="message"
                      onKeyDown={
                        !chatId
                          ? (e) => {
                              if (e.key === "Enter" && !e.shiftKey && context) {
                                e.preventDefault();
                                if (context.trim().length === 0) return;
                                createNewChat("chat");
                                setContext("");
                              }
                              if (e.key === "Enter" && e.shiftKey) {
                                setContext(context + "\n");
                                e.preventDefault();
                              }
                            }
                          : (e) => {
                              if (e.key === "Enter" && !e.shiftKey && context) {
                                e.preventDefault();
                                if (context.trim().length === 0) return;
                                askNeo(chatId);
                                setContext("");
                              }
                              if (e.key === "Enter" && e.shiftKey) {
                                setContext(context + "\n");
                                e.preventDefault();
                              }
                            }
                      }
                    />
                    <div style={{ height: "0" }}>
                      {isLoading ? (
                        <Loading
                          type="points-opacity"
                          color="white"
                          size="lg"
                          style={{
                            color: "white",
                            position: "relative",
                            top: "-35px",
                            left: "96%",
                          }}
                        />
                      ) : (
                        <SendOutlined
                          style={{
                            color: "white",
                            position: "relative",
                            top: "-35px",
                            left: "96%",
                          }}
                          onClick={
                            !chatId
                              ? () => {
                                  createNewChat("chat");
                                }
                              : () => {
                                  askNeo(chatId);
                                }
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={isMobile ? "footer" : ""}></div>
    </div>
  );
}
