import { useNavigate } from "react-router-dom";
// 代码示例：https://github.com/Authing/Guard/blob/master/examples/guard-react18/normal/src/pages/Embed.tsx
import { AuthenticationClient } from "authing-js-sdk";
import AcmeLogo from "../../public/logo.svg";
import inviteLogo from "../../public/invite.svg";
import vip from "../../public/vip.svg";
import avatar from "../../public/avatar.svg";
import { useState, useEffect } from "react";
import { DownOutlined, LeftOutlined } from "@ant-design/icons";
import { Dropdown, Menu, message } from "antd";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { UrlConfig } from "../../UrlConfig.js";

import "../../../styles/global.css";
import "../../../styles/Header.css";

function Header(props) {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  const [restDays, setRestDays] = useState("0");
  const [inviteLink, setInviteLink] = useState("");
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      getUserInfo(token);
    }
    if (
      navigator.userAgent.match(/Mobi/i) ||
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/iPhone/i)
    ) {
      setIsMobile(true);
    }
  }, []);

  useEffect(() => {
    if (userInfo) {
      const restDay = moment(userInfo.usageExpireDate)
        .diff(moment(), "days")
        .toString();
      setRestDays(restDay);
      if (restDay === 0) {
        props.expire({ msg: "expire" });
      }
      const link = sessionStorage.getItem("invite_link");
      if (link && link !== "") {
        setInviteLink(link);
      } else {
        getInviteCode();
      }
    }
  }, [userInfo]);

  function handleClick(str) {
    navigate("/login", { state: { type: str } });
  }

  function goHome() {
    navigate("/");
  }

  function goBack() {
    navigate(-1);
  }

  function getUserInfo(id_token) {
    // 获取用户信息
    let myHeaders = new Headers();
    myHeaders.append("Accept", "*/*");
    myHeaders.append("Content-Type", "application/json");
    const raw = { token: id_token };
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
    };
    const url = UrlConfig.get_user_info_url;
    fetch(url, requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data && data.code === 200) {
          setUserInfo(data.data);
          localStorage.setItem("userInfo", JSON.stringify(data.data));
        } else if (data && data.code === 401) {
          message.error("登陆过期，请重新登录");
        } else {
          message.error(data.message);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  }

  function getInviteCode() {
    let myHeaders = new Headers();
    myHeaders.append("Accept", "*/*");
    const token = localStorage.getItem("token");
    myHeaders.append("Content-Type", "application/json");
    const raw = { token: token };
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
    };
    const url = UrlConfig.get_invite_code_url;
    fetch(url, requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data && data.code === 200) {
          const link = `使用快写AI产品，注册就送十天使用期限：https://www.writefast.tech/login?code=${data.data.invite_code}`;
          // const link = `localhost:3000/login?code=${data.data.invite_code}`
          setInviteLink(link);
          sessionStorage.setItem("invite_link", link);
        } else if (data && data.code === 401) {
          message.error("登陆过期，请重新登录");
        } else {
          message.error(data.msg);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  }

  function logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("userInfo");
    setUserInfo(null);
    props.logOut({ msg: "logout" });
    navigate("/");
  }

  function goToPersonal() {
    navigate("/personal");
  }

  function goToMobilePersonal() {
    navigate("/mobile-personal");
  }

  function goToPay() {
    navigate("/generator", { state: { type: "pay" } });
    props.goPay({ msg: "pay" });
  }

  return (
    <div>
      <div
        className={`header ${isMobile ? "text-center" : "grid"}`}
        style={{ padding: `${isMobile ? "24px" : "24px 48px"}` }}
      >
        {isMobile && props.pageName !== "Home" ? (
          <LeftOutlined
            style={{
              color: "white",
              fontWeight: "600",
              position: "absolute",
              left: "20px",
              top: "35px",
              zIndex: "11",
            }}
            onClick={goBack}
          />
        ) : (
          ""
        )}
        <div
          className="pointer relative"
          style={{ zIndex: "9" }}
          onClick={goHome}
        >
          <img className="mr-6" src={AcmeLogo} alt="" />
        </div>
        <div className="flex white">
          <div
            style={{ width: "100%", textAlign: "right", position: "relative" }}
          >
            {!userInfo ? (
              <div className={`${isMobile ? "mobile-login" : ""}`}>
                <div
                  className="login font-16 font-600 pointer"
                  onClick={() => {
                    handleClick("login");
                  }}
                >
                  登录
                </div>
                {isMobile ? (
                  <div></div>
                ) : (
                  <div
                    className="register btn ml-16 pointer font-600 radius-8"
                    onClick={() => {
                      handleClick("register");
                    }}
                  >
                    现在注册
                  </div>
                )}
              </div>
            ) : (
              <div>
                {isMobile ? (
                  <div
                    className={`${
                      props.pageName === "Home" ? "avatar-mobile" : ""
                    }`}
                    onClick={goToMobilePersonal}
                  >
                    <img
                      className="relative"
                      style={{ top: "-35px", zIndex: "9" }}
                      src={avatar}
                    ></img>
                  </div>
                ) : (
                  <div
                    className="flex"
                    style={{ alignItems: "center", justifyContent: "end" }}
                  >
                    <div
                      className="register btn font-600 radius-8 rest-days"
                      style={{ color: "gray", marginRight: "16px" }}
                    >
                      使用期限剩余
                      <span style={{ color: "white" }}>
                        {" "}
                        {restDays >= 0 ? restDays : 0}{" "}
                      </span>
                      天
                    </div>
                    <CopyToClipboard
                      text={inviteLink}
                      onCopy={() => {
                        if (inviteLink) {
                          message.success("邀请链接已复制，赶快发给小伙伴吧");
                        } else {
                          message.error("链接生成失败，请刷新页面后重试");
                        }
                      }}
                    >
                      <div className="invite pointer btn font-600 radius-8 flex relative">
                        <img
                          className="relative"
                          style={{ top: "4px" }}
                          src={inviteLogo}
                        ></img>
                        <span className="ml-4">邀1人 送1天</span>
                      </div>
                    </CopyToClipboard>
                    <div
                      className="upgrade pointer ml-8 btn font-600 radius-8 flex relative"
                      onClick={goToPay}
                    >
                      <span className="m6-4">即刻升级VIP</span>
                      <img
                        className="relative"
                        style={{ left: "4px" }}
                        src={vip}
                      ></img>
                    </div>
                    <Dropdown
                      overlayClassName="dropdown"
                      overlay={
                        <Menu>
                          <Menu.Item key="personal" onClick={goToPersonal}>
                            <div>个人中心</div>
                          </Menu.Item>
                          <Menu.Item key="logout" onClick={logout}>
                            <div>退出登录</div>
                          </Menu.Item>
                        </Menu>
                      }
                      trigger={["click"]}
                    >
                      <div className="user-basic relative pointer">
                        <img
                          className="relative"
                          style={{ top: "10px" }}
                          src={avatar}
                        ></img>
                        <span className="font-600 ml-8 mr-6">{`${userInfo.phone.substr(
                          0,
                          3
                        )}****${userInfo.phone.substr(7, 10)}`}</span>
                        <DownOutlined />
                      </div>
                    </Dropdown>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
