import React, { useState, useEffect } from "react";
import QRCode from 'qrcode.react';
import { UrlConfig } from "../../UrlConfig.js";
import { Loading } from "@nextui-org/react";
import '../../../styles/payment.css';
import '../../../styles/paymentMobile.css';
import '../../../styles/global.css';

import { Modal } from "antd";

function PaymentMobile() {
    const [qrCode, setQrCode] = useState('')
    const [payModal, setPayModal] = useState(false)
    const [priceModel, setPriceModel] = useState([])
    const [sku, setSku] = useState(null)
    const [isLoading, setIsLoading] = useState(['false', 'false', 'false', 'false']);


    useEffect(() => {
        getPrice()
    }, [])

    function getPrice() {
        let myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/json") 
        const url = UrlConfig.get_price  
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({})
        }
        fetch(url, requestOptions).then(res => {
            return res.json()
        }).then(data => {
            if (data && data.code === 200) {
                setPriceModel(data.data)
            }
        })
    }

    function buyNow(sku) {
        const temp = isLoading.filter(item => {
            return item.value === 'true'
        })
        if (temp.length > 0) return
        setIsLoading(isLoading.map(item => {
            if (item.id === sku) {
                return {...item, value: 'true'}
            }
            return item
        }))
        setIsLoading(isLoading.map((str, i) => (i === parseInt(sku) ? 'true' : str)));
        let myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/json")
        const token = localStorage.getItem('token')
        const url = UrlConfig.get_pay_qrcode
        const raw = {
            sku: sku,
            token: token  
        }
        switch (sku) {
            case '0':
                setSku('快写套餐')
                break;
            case '1':
                setSku('快写一个月套餐')
                break;
            case '3':
                setSku('快写三个月套餐')
                break;
            case '6':
                setSku('快写半年套餐')
                break;
            default:
                break;
        }
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify(raw)
        }
        fetch(url, requestOptions).then(res => {
            return res.json()
        }).then(data => {
            if (data && data.code === 200) {
                setQrCode(data.data.qr_code_url)
                setIsLoading(isLoading.map((str, i) => (i === parseInt(sku) ? 'false' : str)));
                setPayModal(true)
            }
        })
    }

    function closeHandler() {
        setPayModal(false)
    }

    function paid () {
        setPayModal(false)
        window.location.reload()
    }
    return (
        <div className="payment-body flex">
            <div>
                <div className="cards flex mt-6">
                {priceModel && priceModel.length > 0 ? (
                        <div className="grid grid-1-1">
                            {priceModel.map((item, index) => {
                                return <div className="pay-card-mobile" key={index}>
                                    <div className="card-title-mobile text-center font-12">{item.name}</div>
                                    <div className="card-body">
                                        <div className="pay-price text-center mt-6 font-24 font-600">{item.price / 100}元</div>
                                        <div className="text-center font-600 text-grey font-14 mt-6 pb-14 text-decoration-line">{item.priceBefore / 100}元</div>
                                        <div className="text-center font-600 text-grey font-14 mt-8 pb-14">{item.description}</div>
                                        <div className="text-white font-18 text-center pb-14" onClick={() => { buyNow(item.sku) }}>
                                            {isLoading.filter(ele => {
                                                return ele.id === item.sku && ele.value === 'true'
                                            }).length > 0 ? (
                                                <Loading type="points-opacity" color="white" size="lg" />
                                            ): (
                                                <div>立即购买</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    ) : (<div></div>)}
                </div>
            </div>
            <Modal
                open={payModal}
                onCancel={closeHandler}
                style={{
                top: 200,
                }}
                footer={null}>
                <div className="text-center white font-400 font-24">
                    请使用微信扫码支付
                </div>
                <div className="text-center font-400 font-14 mt-8 mb-24 white">
                    套餐：{sku}
                </div>
                <div className="text-center mt-22">
                    <QRCode
                        value={qrCode} //value参数为字符串类型
                        size={200} //二维码的宽高尺寸
                        fgColor="#000000"  //二维码的颜色
                    />
                </div>
                <div className="text-center font-400 font-14 mt-8 mb-24 white">
                    付款完成后请刷新页面更新使用期限
                </div>
                <div className="paid btn-default pointer register-btn" onClick={paid} style={{width: '50%', margin: '0 auto'}}>付款完成</div>
            </Modal>
        </div>
    )
}

export default PaymentMobile;