import {
  Grid,
  Card,
  Text,
  Container,
  Row,
  Col,
  Navbar,
  Spacer,
  Button,
} from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
// 代码示例：https://github.com/Authing/Guard/blob/master/examples/guard-react18/normal/src/pages/Embed.tsx
import { AuthenticationClient } from "authing-js-sdk";
import { UrlConfig } from "./UrlConfig";
import LoginUtils from "./LoginUtils";
import { useState, useEffect } from "react";
import Header from './public/components/Header';
import LandingBg from './public/landing-bg.png'
import HomeMobile from './public/home-mobile.png'

import '../styles/global.css';
import '../styles/Home.css';

function Home() {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false)

  function handleClick(e) {
    navigate("/generator")
  }
  
  useEffect(() => {
    if (
      navigator.userAgent.match(/Mobi/i) ||
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/iPhone/i)
    ) {
      setIsMobile(true)
    }
  }, [])

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: `${isMobile? '' : 'auto'}`
      }}
      className="body"
    >
      <Header pageName={'Home'} />
      {/* <Spacer y={2} /> */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          margin: "20px",
        }}
      >
        {!isMobile ? (<div></div>) : (
          <div className="right">
            <div className="img-container-mobile"><img src={HomeMobile}></img></div>
          </div>
        )}
        <div className={`container white ${isMobile ? 'p-30' : 'p-46 grid'}`}>
          <div className={`${isMobile ? '' : 'left'}`}>
            <div className={`${isMobile ? 'font-45' : 'font-62'} font-400 left-transform title`}>写优质文案</div>
            <div className={`${isMobile ? 'font-45' : 'font-62'} font-400 left-transform title`}>就用快写</div>
            <div className="left-text font-18">
              老板让你打工，你让 AI 给你打工
            </div>
            {localStorage.getItem('userInfo') ? (<div className="free-use-btn pointer font-600" onClick={handleClick}>开始使用</div>) : (
              <div className={`free-use-btn pointer font-600 ${isMobile ? 'free-btn-mobile' : 'free-btn-pc'}`} onClick={handleClick}>
               免费试用
              </div>
            )}
          </div>
          {isMobile ? (<div></div>) : (
            <div className="right">
              <div className="img-container"><img src={LandingBg}></img></div>
            </div>
          )}
        </div>
        {/* <Card variant="bordered" isPressable isHoverable onClick={handleClick}>
          <Card.Body>
            <Text size={30} weight="bold" style={{ textAlign: "center" }}>
              ⚡开始辅助写文案⚡
            </Text>
          </Card.Body>
        </Card> */}
      </div>
      <div className={isMobile ? 'footer' : ''}></div>
    </div>
  );
}

export default Home;
