//localtest configuration 不要删除
// export const UrlConfig = {
//     redirect_url:"http://127.0.0.1:5000/login/callback",
//     post_chat_url:"http://127.0.0.1:5000/chat",
//     post_freechat_url:"http://127.0.0.1:5000/freechat",
//     post_freechat_stream_url:"http://127.0.0.1:5000/stream/freechat",
//     send_code_url: "http://127.0.0.1:5000/sms",
//     register_url: "http://127.0.0.1:5000/register/phone",
//     login_url: "http://127.0.0.1:5000/login/phone",
//     get_user_info_url: "http://127.0.0.1:5000/user/info",
//     get_invite_code_url: "http://127.0.0.1:5000/invite/code"
// }

//staging server 不要删除
// export const UrlConfig = {
//     redirect_url:"https://writingbuddy.xyz/login/callback",
//     post_chat_url:"https://writingbuddy.xyz/chat",
//     post_freechat_url:"https://writingbuddy.xyz/freechat",
//     post_freechat_stream_url:"https://writingbuddy.xyz/stream/freechat",
//     send_code_url: "https://writingbuddy.xyz/sms",
//     register_url: "https://writingbuddy.xyz/register/phone",
//     login_url: "https://writingbuddy.xyz/login/phone",
//     get_user_info_url: "https://writingbuddy.xyz/user/info",
//     get_invite_code_url: "https://writingbuddy.xyz/invite/code",
//     get_invite_list: "https://writingbuddy.xyz/user/invite_list",
//     get_pay_qrcode: 'https://writingbuddy.xyz/order/create',
//     create_new_chat: 'https://writingbuddy.xyz/stream/new/chat',
//     post_chat_by_list: 'https://writingbuddy.xyz/stream/chat',
//     get_history_chat: 'https://writingbuddy.xyz/stream/chat/history',
//     get_price: 'https://writingbuddy.xyz/product/all_product',
//     pay_status: 'https://writingbuddy.xyz/order/pay_status',
//     change_chat_name: 'https://writingbuddy.xyz/stream/chat/name',
//     dns:'https://writingbuddy.xyz/',
//     order_list:'https://writingbuddy.xyz/order/all_order'
// }

//production configuration
export const UrlConfig = {
    redirect_url:"https://writefastprod.xyz/login/callback",
    post_chat_url:"https://writefastprod.xyz/chat",
    post_freechat_url:"https://writefastprod.xyz/freechat",
    post_freechat_stream_url:"https://writefastprod.xyz/stream/freechat",
    send_code_url: "https://writefastprod.xyz/sms",
    register_url: "https://writefastprod.xyz/register/phone",
    login_url: "https://writefastprod.xyz/login/phone",
    get_user_info_url: "https://writefastprod.xyz/user/info",
    get_invite_code_url: "https://writefastprod.xyz/invite/code",
    get_invite_list: "https://writefastprod.xyz/user/invite_list",
    get_pay_qrcode: 'https://writefastprod.xyz/order/create',
    create_new_chat: 'https://writefastprod.xyz/stream/new/chat',
    post_chat_by_list: 'https://writefastprod.xyz/stream/chat',
    get_history_chat: 'https://writefastprod.xyz/stream/chat/history',
    get_price: 'https://writefastprod.xyz/product/all_product',
    pay_status: 'https://writefastprod.xyz/order/pay_status',
    change_chat_name: 'https://writefastprod.xyz/stream/chat/name',
    dns:'https://writefastprod.xyz/',
    order_list:'https://writefastprod.xyz/order/all_order'
}


