// import logo from './logo.svg';
import "./App.css";
import { Grid, Card, Text, Container, Row, Col } from "@nextui-org/react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Generator from "./pages/Generator";
import Login from "./pages/Login";
import Home from "./pages/Home";
import MobileGenerator from "./pages/MobileGenerator";
import Personal from './pages/Personal';
import PersonalMobile from './pages/PersonalMobile';
import Callback from "./pages/Callback";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { NextUIProvider } from "@nextui-org/react";
import { UrlConfig } from "./pages/UrlConfig";
import { message } from "antd";

//github sentry tracing
Sentry.init({
  dsn: "https://22a5609f38714a0489fde75dedd9a158@o1039855.ingest.sentry.io/4504767411650560",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

// baidu tracing
var _hmt = _hmt || [];
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?e31fcc0a5e4f81f155780b7b24f27c9d";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();

// dns lookup
const requestOptions = {
  method: "GET",
};
const url = UrlConfig.dns;
fetch(url, requestOptions)
  .then((res) => {
    return res;
  })
  .catch((err) => {
    console.log('DNS http failed');
  });

function App() {
  return (
    <NextUIProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/generator" element={<Generator />} />
          <Route path="/login" element={<Login />} />
          <Route path="/personal" element={<Personal />} />
          <Route path="/logged_in" element={<Callback />} />
          <Route path="/mobile-generator" element={<MobileGenerator />} />
          <Route path="/mobile-personal" element={<PersonalMobile />} />
        </Routes>
      </BrowserRouter>
    </NextUIProvider>
  );
}

export default App;
