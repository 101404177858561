import { AuthenticationClient } from "authing-js-sdk";
import { UrlConfig } from "./UrlConfig";

class LoginUtils {
  saveToken(token) {
    localStorage.setItem("id_token", token);
  }

  getToken() {
    let token = localStorage.getItem("id_token");
    if (token === null) {
      return "";
    }
    return token;
  }

  isLoggedIn() {
    let token = this.getToken();
    if (token === "") {
      console.log("No token found");
      return false;
    }
    console.log("token found");
    return true;
  }

  logOut() {
    localStorage.removeItem("id_token");
  }

  buildLoginUrl() {
    const client = new AuthenticationClient({
      appId: UrlConfig.id,
      appHost: UrlConfig.host,
      redirectUri: UrlConfig.redirect_url,
    });
    return client.buildAuthorizeUrl();
  }
}

export default LoginUtils;
