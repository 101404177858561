import React, { useState, useEffect } from "react";
import { UrlConfig } from "./UrlConfig.js";
import Header from "./public/components/Header";
import { message } from 'antd';
import { useNavigate } from "react-router-dom";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import inviteLogo from "./public/invite.svg";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PaymentMobile from "./public/components/PaymentMobile";
import moment from "moment";

import moblieLogo from './public/mobile-logo.png'

import avatar from "./public/avatar.svg";

import "../styles/PersonalMobile.css";

export default function PersonalMobile() {
    const [inviteList, setInviteList] = useState([])
    const [headerText, setHeaderText] = useState('我的信息')
    const [inviteLink, setInviteLink] = useState('')
    const [phoneNum, setPhoneNum] = useState(null)
    const [restDay, setRestDays] = useState("0");
    const navigate = useNavigate()

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'))
        if (userInfo) {
          getInviteList()
          getInviteCode()
          const num = `${userInfo.phone.substr(0, 3)}****${userInfo.phone.substr(7, 10)}`
          setPhoneNum(num)
          const restDay = moment(userInfo.usageExpireDate)
          .diff(moment(), "days")
          .toString();
          setRestDays(restDay);
        }
      }, [])

    function getInviteList() {
        let myHeaders = new Headers()
        myHeaders.append("Accept", "*/*")
        const token = localStorage.getItem('token')
        myHeaders.append("Content-Type", "application/json")
        const raw = {token: token}
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(raw)
        }
        const url = UrlConfig.get_invite_list
        fetch(url, requestOptions).then(res => {
            return res.json()
          }).then(data => {
            if (data && data.code === 200) {
              setInviteList(data.data)
              console.log(data)
            } else {
              message.error(data.msg)
            }
          }).catch(err => {
            message.error(err)
        })
    }

    function logout() {
      localStorage.removeItem('token')
      localStorage.removeItem('userInfo')
      localStorage.removeItem('restDay')
      navigate('/')
    }
    function goBack() {
      if (headerText === '付费升级') {
        setHeaderText('我的信息')
      } else {
        navigate(-1)
      }
    }
    function getInviteCode() {
      let myHeaders = new Headers()
      myHeaders.append("Accept", "*/*")
      const token = localStorage.getItem('token')
      myHeaders.append("Content-Type", "application/json")
      const raw = {token: token}
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(raw)
      }
      const url = UrlConfig.get_invite_code_url
      fetch(url, requestOptions).then(res => {
        return res.json()
      }).then(data => {
        if (data && data.code === 200) {
          const link = `使用快写AI产品，注册就送十天使用期限：https://www.writefast.tech/login?code=${data.data.invite_code}`
          // const link = `localhost:3000/login?code=${data.data.invite_code}`
          setInviteLink(link)
        } else {
          message.error(data.msg)
        }
      }).catch(err => {
        message.error(err)
      })
    }
    function upgrade() {
      setHeaderText('付费升级')
    }
    return (
      <div className="body" style={{height: '100vh'}}>
        <div className="personal-header grid text-white">
          <LeftOutlined style={{
           color: 'white',
           fontWeight: '600',
           textAlign: 'left'
          }} onClick={goBack} />
          <div className="text-center font-600">{headerText}</div>
          <div className="text-right" style={{opacity: '0.7'}} onClick={logout}>退出登录</div>
        </div>
        <div className="p-20">
          {headerText === '我的信息' ? (
            <div>
              <div className="invite-card flex mb-14">
              <div className="card-left">
                <div className="text-grey font-600">使用期限剩余</div>
                <div className="text-white">
                  <span style={{fontSize: '28px'}}>{restDay}</span>
                  <span> 天</span>
                </div>
                <div>
                  <CopyToClipboard text={inviteLink}
                    onCopy={() => {
                      if (inviteLink) {
                        message.success('邀请链接已复制，赶快发给小伙伴吧')
                      } else {
                        message.error('链接生成失败，请刷新页面后重试')
                      }
                    }}>
                    <div className="invite pointer btn font-600 radius-8 flex relative" style={{marginTop: '20px'}}>
                      <img className="relative" style={{top: '4px'}} src={inviteLogo}></img>
                      <span className="ml-4">邀好友 无限用</span>
                    </div>
                  </CopyToClipboard>
                </div>
                <div></div>
              </div>
              <div className="card-right">
                <img src={moblieLogo}></img>
              </div>
            </div>
            <div className="pay-inside flex text-white" onClick={upgrade}>
                <div className="flex-1">付费升级</div>
                <RightOutlined style={{width: '24px'}} />
            </div>
            <div className="pay-inside flex text-white">
                <div className="flex-1">我的信息</div>
                <div className="text-right text-grey font-600" style={{width: '164px'}}>手机号: {phoneNum}</div>
              </div>
            <div className="mt-14">
              <div className="text-grey font-600">邀请的好友</div>
              <div className="mt-14 invite-list">
                {inviteList.map((item, index) => {
                  return (
                    <div className="list-item flex" key={index}>
                      <img src={avatar} alt="" />
                      <div className="text-white ml-8 font-600">{item.nickname}</div>
                    </div>
                  )
                })}
              </div>
            </div>
            </div>
          ) : (
            <PaymentMobile />
          )}
        </div>
      </div>
    )
}